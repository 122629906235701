<template>
  <v-expand-transition>
    <LayoutEditorToolbar
      v-show="buttons.length > 0 || !withoutHelperTools.includes(tool)"
      :buttons="buttons"
      class="toolbar-style"
    >
      <template v-slot:button="{ button }">
        <ToolButton
          :tooltip="button.tooltip"
          :icon="button.icon"
          :active="button.name === toolSubcategory"
          :color="button.color"
          :label="button.label"
          @clicked="() => onChangeSubcategory(button)"
          topTooltip
        ></ToolButton>
      </template>
      <v-layout slot="append" wrap>
        <v-divider
          class="mx-2"
          v-if="!$vuetify.breakpoint.smAndDown && buttons.length > 0"
          vertical
        ></v-divider>
        <v-flex
          xs6
          v-if="tool != 'selectMode'"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'mx-4'
              : buttons.length === 0 && 'mx-1'
          "
        >
          <LineSizeSlider
            v-if="tool != 'text'"
            :value.sync="internalStrokeWidth"
          ></LineSizeSlider>
          <TextSizeSlider v-else :value.sync="internalSize"></TextSizeSlider>
        </v-flex>
      </v-layout>
    </LayoutEditorToolbar>
  </v-expand-transition>
</template>

<script>
import ToolsMixin from "@/mixins/editors/ToolsMixin";

export default {
  mixins: [ToolsMixin],
  data() {
    return {
      withoutHelperTools: [
        "selectMode",
        "scar-reddening",
        "scar-bulge",
        "scar-retraction",
      ],
    };
  },
  computed: {
    internalStrokeWidth: {
      get() {
        return this.toolStrokeWidth;
      },
      set(value) {
        this.changeToolStrokeWidth(value);
      },
    },
    internalSize: {
      get() {
        return this.toolSize;
      },
      set(value) {
        this.changeToolSize(value);
      },
    },
    buttons() {
      switch (this.toolCategory) {
        case "shapes":
          return [
            {
              tooltip: "Kwadrat",
              name: "rect",
              icon: require("@/assets/editor/rectangle.svg"),
            },
            {
              tooltip: "Koło",
              name: "circle",
              icon: require("@/assets/editor/circle.svg"),
            },
            {
              tooltip: "Trójkąt",
              name: "triangle",
              icon: require("@/assets/editor/triangle.svg"),
            },
            {
              tooltip: "Linia",
              name: "line",
              icon: require("@/assets/editor/line.svg"),
            },
          ];
        case "shapes-dashed":
          return [
            {
              tooltip: "Kwadrat z wypełnieniem",
              name: "rect-dashed",
              icon: require("@/assets/editor/rectangle-filled.svg"),
            },
            {
              tooltip: "Koło z wypełnieniem",
              name: "circle-dashed",
              icon: require("@/assets/editor/circle-filled.svg"),
            },
            {
              tooltip: "Trójkąt z wypełnieniem",
              name: "triangle-dashed",
              icon: require("@/assets/editor/triangle-filled.svg"),
            },
            {
              tooltip: "Linia przerywana",
              name: "line-dashed",
              icon: require("@/assets/editor/line-filled.svg"),
            },
          ];
        case "pencil":
          return [
            {
              tooltip: "Linia ciągła",
              name: "freeDrawing",
              icon: require("@/assets/editor/line.svg"),
            },
            {
              tooltip: "Linia kreskowana",
              name: "freeDrawing-dashed",
              icon: require("@/assets/editor/line-filled.svg"),
            },
          ];
        case "curve":
          return [
            {
              tooltip: "Linia ciągła",
              name: "curve",
              icon: require("@/assets/editor/line.svg"),
            },
            {
              tooltip: "Linia kreskowana",
              name: "curve-dashed",
              icon: require("@/assets/editor/line-filled.svg"),
            },
            {
              tooltip: "Zatwierdź i rozpocznij rysowanie nowej krzywej",
              label: "Rysuj nową krzywą",
              name: "new-curve",
              icon: require("@/assets/editor/curve.svg"),
            },
          ];
        default:
          return [];
      }
    },
  },
  watch: {
    toolCategory() {
      if (this.buttons.length > 0) {
        this.onChangeSubcategory(this.buttons[0]);
      }
    },
  },
  components: {
    ToolButton: () => import("@/components/editors/ToolButton"),
    LayoutEditorToolbar: () =>
      import("@/components/editors/toolbars/LayoutEditorToolbar"),
    LineSizeSlider: () => import("@/components/editors/LineSizeSlider"),
    TextSizeSlider: () => import("@/components/editors/TextSizeSlider"),
  },
  methods: {
    onChangeSubcategory(tool) {
      //Special case to restart drawing new curve:
      if (tool.name == "new-curve") {
        this.changeTool("selectMode").then(() => this.changeTool("curve"));
        return;
      }

      let name = tool.name;
      if (name.includes("-dashed")) {
        this.changeToolDashed("true");
        name = name.replace("-dashed", "");
      } else {
        this.changeToolDashed("false");
      }

      this.changeToolSubcategory(tool.name);
      this.changeTool(name);
    },
  },
};
</script>

<style scoped>
.toolbar-style {
  background-color: #f6f7fb !important;
}
.break {
  height: 1px !important;
  width: 100% !important;
}
</style>